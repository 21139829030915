<script setup>
import { onMounted, ref } from 'vue'
import { useToast } from 'primevue/usetoast'
import { FilterMatchMode } from 'primevue/api'
import OwnerService from '@/apps/dailyTech/inventario-ti/services/owners.service'
import Icon from '@/components/Icon/index.vue'
import Swal from 'sweetalert2'

const toast = useToast()
const _OwnerService = ref(new OwnerService())
const modalOwner = ref(false)
const listOwners = ref([])
const owner = ref({})
const submitted = ref(false)
const isEdit = ref(false)
const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS }
})

// Obtener el listado de dueños
const fetchOwners = async () => {
  try {
    const { status, data } = await _OwnerService.value.getOwners()
    if (status === 200) {
      listOwners.value = data
    }
  } catch (error) {
    console.error(error.message)
  }
}

// Abrir el modal
const openModalOwner = (item) => {
  modalOwner.value = true
  submitted.value = false

  if (item) {
    owner.value = { ...item }
    isEdit.value = true
  } else {
    owner.value = {
      id: null,
      ownername: '',
      status: true
    }
    isEdit.value = false
  }
}

// Cerrar el modal
const hideModalOwner = () => {
  modalOwner.value = false
  submitted.value = false
}

// Crear un dueño
const createOwner = async () => {
  try {
    // Validar que el dueño tenga un nombre
    if (!owner.value.ownername) {
      toast.add({ severity: 'error', summary: 'Error', detail: 'El nombre es requerido', life: 3000 })
      return
    }
    const { status } = await _OwnerService.value.createOwner(owner.value)
    if (status === 200) {
      toast.add({ severity: 'success', summary: 'Exito', detail: 'Dueño creada exitosamente', life: 3000 })
      await fetchOwners()
      hideModalOwner()
      modalOwner.value = false
      owner.value = {}
    }
  } catch (error) {
    console.error(error.message)
  }
}

// Actualizar un dueño
const updateOwner = async () => {
  try {
    // Validar que el dueño tenga un id y nombre
    if (!owner.value.id || !owner.value.ownername) {
      toast.add({ severity: 'error', summary: 'Error', detail: 'El nombre es requerido', life: 3000 })
      return
    }

    const { status } = await _OwnerService.value.editOwner(owner.value.id, owner.value)
    if (status === 200) {
      toast.add({ severity: 'success', summary: 'Exito', detail: 'Dueño actualizado exitosamente', life: 3000 })
      await fetchOwners()
      hideModalOwner()
      modalOwner.value = false
      owner.value = {}
    }
  } catch (error) {
    console.error(error.message)
  }
}

// Guardar o actualizar un dueño
const onSubmit = async () => {
  submitted.value = true
  if (isEdit.value && owner.value.id) {
    await updateOwner()
  } else {
    await createOwner()
  }
}

// Eliminar un dueño
const deleteOwner = async (id) => {
  try {
    if (id) {
      Swal.fire({
        icon: 'warning',
        title: 'Advertencia',
        text: 'Desea eliminar el dueño?, esta acción no se puede revertir.',
        showDenyButton: true,
        denyButtonText: 'No, Cancelar',
        confirmButtonText: 'Si, Eliminar'
      }).then((result) => {
        if (result.isConfirmed) {
          _OwnerService.value.deleteOwner(id).then(({ status }) => {
            if (status !== 200) return

            toast.add({ severity: 'success', summary: 'Exito', detail: 'Dueño eliminado exitosamente', life: 3000 })
            fetchOwners()
            hideModalOwner()
            modalOwner.value = false
            owner.value = {}
          }).catch((err) => {
            console.error(err.message)
            if (err.response.status) {
              toast.add({ severity: 'error', summary: 'Error', detail: 'No se ha podido eliminar el dueño', life: 3000 })
            }
          })
        }
      })
    } else {
      toast.add({ severity: 'error', summary: 'Error', detail: 'No se ha podido eliminar el dueño', life: 3000 })
    }
  } catch (error) {
    console.error(error.message)
  }
}

// Hook onMounted
onMounted(async () => {
  await fetchOwners()
})
</script>

<template>
  <Toast />
  <Toolbar class="box mt-4 py-2 px-4">
    <template #start>
      <Button label="Regresar" icon="pi pi-arrow-left" class="p-button-sm" @click="$router.push({ name: 'daily-tech.inventario-ti.configuracion'})"/>
    </template>
    <template #end>
      <Button
        label="Agregar"
        icon="pi pi-plus"
        size="small"
        severity="success"
        @click="openModalOwner(null)"
      />
    </template>
  </Toolbar>

  <!-- Listado de los dueños -->
  <div class="box mt-4 intro-x">
    <DataTable
      showGridlines
      ref="dt"
      :value="listOwners"
      class="p-datatable-sm p-4 text-sm"
      dataKey="id"
      :paginator="true"
      :rows="10"
      :filters="filters"
      sortField="id"
      :sortOrder="-1"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[5,10,25,50]"
      currentPageReportTemplate="Mostrando registros del {first} al {last} de un total de {totalRecords} registros"
      responsiveLayout="scroll"
    >
      <template #header>
        <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
          <h3 class="p-mb-2 p-m-md-0 p-as-md-center text-lg">Listado de las dueños</h3>
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" placeholder="Buscar..." />
          </span>
        </div>
      </template>

      <!-- Columnas -->
      <template #empty>
        <div class="flex flex-col items-center justify-center" style="height: 500px">
          <Icon icon="fluent-emoji-flat:card-index" class="text-gray-200" style="font-size: 15rem; opacity: 0.3"/>
          <span class="text-gray-400 text-2xl">No se encontraron registros</span>
          <span class="text-gray-300">No hemos encontrado ningún elemento, pruebe con otro filtro u opción</span>
        </div>
      </template>
      <template #loading> Cargando la información, por favor espere... </template>
      <Column field="id" header="Id" :sortable="true"></Column>
      <Column field="ownername" header="Nombre" :sortable="true"></Column>
      <Column field="status" header="Estado" :sortable="true">
        <template #body="{ data }">
          <span :class="'bank-badge px-2 py-1 text-xs font-bold leading-none text-white rounded ' + (data.status === true ? 'bg-green-700' : 'bg-red-700')">{{data.status === true ? 'Activo' : 'Inactivo'}}</span>
        </template>
      </Column>
      <Column :exportable="false" header="Acciones" style="text-align: center">
        <template #body="{ data }">
          <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning p-mr-2" @click="openModalOwner(data)" />
          <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="deleteOwner(data.id)" />
        </template>
      </Column>
    </DataTable>
  </div>

  <!-- Modal formulario dueños -->
  <Dialog
    v-model:visible="modalOwner"
    :style="{width: '450px'}"
    :modal="true"
    class="p-fluid"
    :pt="{
      header: { style: 'padding: 1rem;' }
    }"
  >
    <template #header>
      <h3 :class="{'text-yellow-700': isEdit, 'text-green-700': !isEdit}" class="font-bold text-xl">{{isEdit ? 'Editar' : 'Crear'}} dueño</h3>
    </template>
    <div class="p-field">
      <label for="name">Nombre</label>
      <InputText
        id="name"
        v-model="owner.ownername"
        required="true"
        autofocus
        :class="{'p-invalid': submitted && !owner.ownername}"
        class="p-fluid"
      />
      <small class="p-error" v-if="submitted && !owner.ownername">El nombre es requerido.</small>
    </div>
    <div class="p-field">
      <label for="status">Estado</label>
      <InputSwitch v-model="owner.status" id="status" class="p-d-block"/>
    </div>

    <template #footer class="p-text-bold">
      <Button label="Cancelar" icon="pi pi-times" class="p-button-danger p-button-sm" @click="hideModalOwner"/>
      <Button label="Guardar" icon="pi pi-check" class="p-button-success p-button-sm" @click="onSubmit" />
    </template>
  </Dialog>
</template>

<style scoped>

</style>
